import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import style from './style.css';

const ReportHeader = props => {
    const { participant, groupLabel, providerList, dates, groupReport } = props;

    useEffect(() => {}, []);

    return (
        <div className={style.header}>
            <div className={style.background}>
                <div className={style.topRow}>
                    <div>
                        <h1 className={style.groupName}>{groupLabel}</h1>
                    </div>
                    <div className={style.topRight}>
                        <div>
                            <span className={style.bold}>Dates of Service: </span>
                            <div>
                                {dates.startDate} to {dates.endDate}
                            </div>
                        </div>
                        <div>
                            <span className={style.bold}>Date Report Created: </span>
                            <div>{dates.now}</div>
                        </div>
                    </div>
                </div>
                <div className={style.bottomRow}>
                    {providerList.length === 1 ? (
                        <div>
                            <div>
                                <span className={style.bold}>Clinicians: </span>
                                <span style={{ marginRight: '0.5em' }}>{providerList[0].fields.idNumber}</span>
                            </div>
                            {!groupReport && (
                                <div>
                                    <span className={style.bold}>Participant: </span>
                                    <span>{participant}</span>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <div>
                                <span className={style.bold}>Clinicians: </span>
                                {providerList.map(c => c.fields.idNumber).join(', ')}
                            </div>
                            {!groupReport && (
                                <div>
                                    <span className={style.bold}>Participant: </span>
                                    <span>{participant}</span>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
ReportHeader.propTypes = {
    participant: PropTypes.string,
    groupLabel: PropTypes.string,
    providerList: PropTypes.array,
    dates: PropTypes.object,
    groupReport: PropTypes.bool,
};
export default ReportHeader;
