import React from 'react';
import { Route, NotFound } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'react-router-dom';
import { AdminRoutes, Manage, Dashboard as AppdefDashboard } from 'cccisd-laravel-appdefs';
import { AuthRedirect } from 'cccisd-laravel-nexus';
import { Quest } from 'cccisd-laravel-assignment';
import { ResourceCenter } from 'cccisd-laravel-resources';
import ReportCenter from 'cccisd-report-center';

// Layouts
import MainLayout from './layouts/MainLayout';
import QuestLayout from './layouts/QuestLayout';

// Pages
import Welcome from './pages/Welcome'; // Include it into main bundle

// Components
import ProviderDashboard from 'bundle-loader?lazy!./components/ProviderDashboard';
import SessionData from 'bundle-loader?lazy!./components/SessionData';
import ProgressData from 'bundle-loader?lazy!./components/ProgressData';
import ParentSessionData from 'bundle-loader?lazy!./components/ParentSessionData';
import OutcomesData from 'bundle-loader?lazy!./components/OutcomesData';
import ManageGroups from 'bundle-loader?lazy!./components/ManageGroups';

// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

// Bind MainLayout by default
const AppRoute = defaultProps({ layout: MainLayout })(Route);
const MainLayoutFluid = defaultProps({ className: 'container-fluid' })(MainLayout);
const MainLayoutSecondaryNav = defaultProps({ showSecondaryNav: true })(MainLayout);
const MainLayoutFluidSecondaryNav = defaultProps({
    className: 'container-fluid',
    showSecondaryNav: true,
})(MainLayout);

var AppDefinitions = window.cccisd.appDefs;
var Fortress = window.cccisd.fortress;

const match = {
    Manage,
    AppdefDashboard,
    ProviderDashboard,
    SessionData,
    ProgressData,
    ParentSessionData,
    OutcomesData,
    ManageGroups,
    ResourceCenter,
    ReportCenter,
};

const layoutMatch = {
    MainLayout,
    MainLayoutFluid,
    MainLayoutSecondaryNav,
    MainLayoutFluidSecondaryNav,
};

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = match[route.componentHandle];
            if (!Component) {
                return null;
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact={'exact' in route ? route.exact : true}
                    layout={
                        'layout' in route && layoutMatch[route.layout] ? layoutMatch[route.layout] : MainLayoutFluid
                    }
                    layoutProps={route.layoutProps}
                />
            );
        })}

        <AppRoute path="/" component={Welcome} exact />
        <AppRoute path="/welcome" component={Welcome} layout={MainLayoutFluid} />

        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={QuestLayout} />

        {/* Laravel packages routes */}
        {AdminRoutes(MainLayoutFluidSecondaryNav)}

        <AppRoute path="/account" component={Nexus} />
        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {/* Not found page */}
        <AppRoute component={NotFound} />
    </Switch>
);
