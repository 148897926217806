import { Field } from 'cccisd-formik';
import React from 'react';
import GroupSelect from './filters/GroupSelect';

export default [
    {
        name: 'group',
        label: 'Select Group',
        component: props => {
            return (
                <Field name="group">{({ field, form }) => <GroupSelect {...props} field={field} form={form} />}</Field>
            );
        },
    },
];
