import cp6m from './reducers/cp6m.js';
import reportFilters from './reducers/reportFilters';

export default {
    cp6m: {
        reducer: cp6m,
    },
    reportFilters: {
        reducer: reportFilters,
    },
};
