import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page, BrowserOnly } from 'cccisd-laravel-report';
import Loader from 'cccisd-loader';
import { client } from 'cccisd-apollo';
import _format from 'date-fns/format';
import { useDispatch, useSelector } from 'react-redux';

import widgets from '../widgets/widgets.js';
import ReportHeader from '../../common/components/ReportHeader';
import filterFields from '../../common/groupFilterField.js';
import { getActualFields } from '../../common/helpers.js';
import reportStyle from '../../common/reportStyle.css';
import NoGroupRole from '../../common/components/NoGroupRole';
import PrintView from '../../common/components/PrintView';

import metricsQuery from './metrics.graphql';

const Component = props => {
    const [metrics, setMetrics] = useState(null);
    const [headerProps, setHeaderProps] = useState(null);
    const [loading, setLoading] = useState(true);
    const [noProgress, setNoProgress] = useState(null);
    const [showPrintMode, setShowPrintMode] = useState(false);

    const { comments, titleAndDescription } = props.widgets;

    const dispatch = useDispatch();

    useEffect(() => {}, [dispatch]);

    const commentsQuery = useSelector(state => state.app.reportFilters.widgetsLoaded);

    const totalSessions = 10;
    const contactData = {
        query: metricsQuery,
        columnNames: [
            {
                id: 'Participant ID',
            },
            {
                id: 'Sessions Attended',
            },
            {
                id: 'Homework Completed',
            },
            {
                id: 'Engagement Level',
            },
        ],
    };

    useEffect(() => {
        // run Metrics query
        if (noProgress === true) {
            setNoProgress(false);
        }
        (async () => {
            try {
                await client
                    .query({
                        query: contactData.query,
                        fetchPolicy: 'network-only',
                        variables: {
                            pawnId: +props.group,
                        },
                    })
                    .then(response => {
                        const group = response.data.roles.client;

                        const participantList = group.childRoles.participantList;

                        const sessionDataDates = group.sessionDataDates;
                        const startDate = sessionDataDates.earliestCompleted !== null;
                        const endDate = sessionDataDates.latestCompleted !== null;

                        const firstDate = startDate
                            ? JSON.parse(group.sessionDataDates.earliestCompleted).sessionDate
                            : null;

                        const lastDate = endDate
                            ? JSON.parse(group.sessionDataDates.latestCompleted).sessionDate
                            : null;

                        const dateObj = {
                            startDate: startDate ? _format(new Date(firstDate), 'MM/dd/yyyy') : '',
                            endDate: endDate ? _format(new Date(lastDate), 'MM/dd/yyyy') : '',
                            now: _format(Date.now(), 'MM/dd/yyyy'),
                        };

                        if (!startDate || participantList.length < 1) {
                            setNoProgress(true);
                        }

                        setMetrics(participantList);

                        setHeaderProps({
                            groupLabel: group.fields.clientLabel,
                            providerList: group.parentRoles.providerList,
                            dates: dateObj,
                            groupReport: true,
                        });
                        setLoading(false);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [props.filters, commentsQuery]);

    const renderTableRows = () => {
        return (
            <>
                {metrics.map(participant => {
                    return (
                        <tr key={participant.pawn.pawnId}>
                            <td className={reportStyle.numberBox}>{participant.fields.participantId}</td>
                            <td>
                                <div className={reportStyle.numberBox}>
                                    {participant.yesAttendance.sum ? participant.yesAttendance.sum : 0} /{' '}
                                    {totalSessions}
                                </div>
                            </td>
                            <td>
                                <div className={reportStyle.numberBox}>
                                    {participant.yesHomework.sum ? participant.yesHomework.sum : 0}{' '}
                                </div>
                            </td>
                            <td>
                                <div className={reportStyle.numberBox}>
                                    {participant.engagement.avg ? participant.engagement.avg : 0}
                                    {'% '}
                                </div>
                            </td>
                        </tr>
                    );
                })}
            </>
        );
    };

    if (props.noGroups) {
        return <NoGroupRole type="group" />;
    }

    if (props.noParticipants) {
        return <NoGroupRole type="participant" />;
    }

    if (noProgress) {
        return <NoGroupRole type="groupData" />;
    }

    if (loading) {
        return <Loader loading />;
    }

    const switchView = () => {
        setShowPrintMode(!showPrintMode);
    };

    return (
        <Report showPagination={showPrintMode}>
            <Page>
                <BrowserOnly>
                    <PrintView showPrintMode={showPrintMode} switchView={switchView} />
                </BrowserOnly>
                <div className={reportStyle.reportBox}>
                    <ReportHeader {...headerProps} />
                    <div className={reportStyle.reportBody}>
                        {titleAndDescription}
                        <table className={reportStyle.reportTable}>
                            <thead>
                                <tr>
                                    {contactData.columnNames.map(col => {
                                        return <th key={col.id}>{col.id}</th>;
                                    })}
                                </tr>
                            </thead>
                            <tbody>{renderTableRows()}</tbody>
                        </table>
                    </div>
                </div>
                <hr />
                {comments}
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    // redux
    widgetsLoaded: PropTypes.array,
    loading: PropTypes.bool,
    participant: PropTypes.number,
    group: PropTypes.number,
    noGroups: PropTypes.bool,
    noParticipants: PropTypes.bool,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
    getFilterFields: props => {
        return getActualFields(filterFields, props);
    },
})(Component);
