import _min from 'date-fns/min';
import _max from 'date-fns/max';
import _format from 'date-fns/format';
import _isValid from 'date-fns/isValid';
import _addDays from 'date-fns/addDays';

export const getDates = obj => {
    const hasNoSessions = !obj.assignmentProgressSummary.firstDate;
    const hasNoOutcomes = obj.childRoles.participantList.every(item => !item.assignmentProgressSummary.firstDate);

    const hasNoProgress = hasNoSessions && hasNoOutcomes;

    if (hasNoProgress || hasNoOutcomes) {
        return false;
    }
    const startDates = obj.childRoles.participantList.map(item =>
        _addDays(new Date(item.assignmentProgressSummary.firstDate), 1)
    );
    const endDates = obj.childRoles.participantList.map(item =>
        _addDays(new Date(item.assignmentProgressSummary.lastDate), 1)
    );

    startDates.push(_addDays(new Date(obj.assignmentProgressSummary.firstDate), 1));
    endDates.push(_addDays(new Date(obj.assignmentProgressSummary.lastDate), 1));

    const start = _isValid(_min(startDates)) ? _min(startDates) : startDates[0];
    const min = _format(start, 'MM/dd/yyyy');

    const end = _isValid(_max(endDates)) ? _max(endDates) : endDates[0];
    const max = _format(end, 'MM/dd/yyyy');

    const now = _format(Date.now(), 'MM/dd/yyyy');

    return { startDate: min, endDate: max, now };
};

export const convertScore = score => {
    if (score === undefined) {
        return '';
    }

    if (score > 1) {
        return Number(score).toFixed(0);
    }
    return (+score * 100).toFixed(0);
};

export const calcAvg = array => array.reduce((a, b) => a + b) / array.length;

export const getActualFields = (filterFields, props) => {
    const list = filterFields.map(field => {
        if (field.name === 'group') {
            return { ...field, initialValue: props.group };
        }
        if (field.name === 'participant') {
            return { ...field, initialValue: props.participant };
        }

        return field;
    });

    if (props.noGroups) {
        const filteredList = list.filter(item => item.name !== 'group' && item.name !== 'participant');
        return filteredList;
    }

    if (props.noParticipants) {
        const filteredList = list.filter(item => item.name !== 'participant');
        return filteredList;
    }
    return list;
};

export const getActualSelectedFields = filterFields => {
    const list = filterFields.map(item => item.name);

    return list;
};

export const lineChartColors = [
    'hsl(205, 71%, 42%)',
    'hsl(25, 96%, 68%)',
    'hsl(125, 37%, 56%)',
    'hsl(271, 40%, 57%)',
    'hsl(10, 30%, 42%)',
    'hsl(318, 66%, 68%)',
    'hsl(0, 0%, 50%)',
    'hsl(60, 69%, 44%)',
    'hsl(186, 70%, 48%)',
];
