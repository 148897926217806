import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page, BrowserOnly } from 'cccisd-laravel-report';
import Loader from 'cccisd-loader';
import { client } from 'cccisd-apollo';
import _format from 'date-fns/format';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionDataVariables } from '../../../../../reducers/reportFilters';
import _get from 'lodash/get';

import widgets from '../widgets/widgets.js';
import SummaryHeader from './SummaryHeader';
import { convertScore, calcAvg } from '../../common/helpers.js';
import Chart from './Chart';
import NoGroupRole from '../../common/components/NoGroupRole';
import reportStyle from '../../common/reportStyle.css';
import query from './metrics.graphql';
import PrintView from '../../common/components/PrintView';

import IconSmile from 'cccisd-icons/smile';
import IconSad from 'cccisd-icons/wondering';

const Fortress = window.cccisd && window.cccisd.fortress;
const providerPawnId = Fortress.user.acting.id;
const store = window.cccisd.store;

const Component = props => {
    const [headerProps, setHeaderProps] = useState({});
    const [loading, setLoading] = useState(true);
    const [noProgress, setNoProgress] = useState(null);
    const [overallScore, setOverallScore] = useState(null);
    const [chartData, setChartData] = useState([]);
    const [showPrintMode, setShowPrintMode] = useState(false);

    const { titleAndDescription } = props.widgets;

    const dispatch = useDispatch();

    const idNumber = Fortress.user.acting.role_data.idNumber;

    useEffect(() => {
        (async () => {
            dispatch(getSessionDataVariables('parent'));
        })();
    }, [dispatch]);

    const { sessionDataVariables } = useSelector(state => state.app.reportFilters);

    useEffect(() => {
        // run metrics query
        if (noProgress === true) {
            setNoProgress(false);
        }

        (async () => {
            try {
                await client
                    .query({
                        query,
                        fetchPolicy: 'network-only',
                        variables: {
                            providerPawnId,
                        },
                    })
                    .then(response => {
                        const groupLeaderData = response.data.roles;
                        const groups = groupLeaderData.clientSummary;

                        const startDate = groupLeaderData.dates.earliestOverallDate !== null;
                        const endDate = groupLeaderData.dates.latestOverallDate !== null;

                        const firstDate = startDate
                            ? JSON.parse(groupLeaderData.dates.earliestOverallDate).sessionDate
                            : null;

                        const lastDate = endDate
                            ? JSON.parse(groupLeaderData.dates.latestOverallDate).sessionDate
                            : null;

                        const dateObj = {
                            startDate: startDate ? _format(new Date(firstDate), 'MM/dd/yyyy') : '',
                            endDate: startDate ? _format(new Date(lastDate), 'MM/dd/yyyy') : '',
                            now: _format(Date.now(), 'MM/dd/yyyy'),
                        };

                        if (!startDate || !dateObj) {
                            setNoProgress(true);
                        }

                        setHeaderProps({
                            idNumber: Fortress.isSuperUser() ? '' : idNumber,
                            groupCount: groupLeaderData.clientList.length,
                            participantCount: groups.totalParticipants,
                            dates: dateObj,
                        });

                        const convertedFidelity = convertScore(groups.overallFidelity);

                        const overallScores = [+convertedFidelity];

                        const bigAvg = calcAvg(overallScores).toFixed(0);

                        setOverallScore(bigAvg);

                        const totalNumberOfSessions = _get(
                            store.getState(),
                            'app.reportFilters.sessionDataVariables.totalSessions'
                        );

                        const sessDataArray = (length, startAt = 1) => {
                            return [...Array(length).keys()].map(i => {
                                return {
                                    // give the chart's x axis the session number
                                    x: i + startAt,

                                    // the y axis is the average of that sessions score, for that devTag
                                    y: getAllGroupsData(groupLeaderData.clientList, `${i + startAt}`),
                                };
                            });
                        };

                        setChartData([
                            {
                                id: 'bigChart',
                                color: 'hsl(195, 100%, 30%)',
                                data: sessDataArray(totalNumberOfSessions),
                            },
                        ]);

                        setLoading(false);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
        // TODO as uber props.filters
    }, []);

    const renderTargetInfo = () => {
        let icon = <IconSmile />;
        let color = '#3673b6';
        let targetText;

        if (+overallScore > sessionDataVariables.fidelityBenchmark) {
            targetText = 'Above Target';
        } else if (+overallScore === sessionDataVariables.fidelityBenchmark) {
            targetText = 'At Target';
        } else {
            icon = <IconSad />;
            color = '#d03916';
            targetText = 'Below Target';
        }

        const score = `${overallScore}%`;

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ textAlign: 'center' }}>
                    <div className={reportStyle.bigScore} style={{ color }}>
                        <div className={reportStyle.targetIndicator} style={{ color }}>
                            <h1>{targetText}</h1>
                        </div>
                        <div>{icon}</div>
                        <div>{score}</div>
                    </div>
                </div>
                <div style={{ width: '75%' }}>
                    <div style={{ height: '250px', width: '100%' }}>
                        <Chart data={chartData} sessionType="parent" />
                    </div>
                </div>
            </div>
        );
    };

    const getAllGroupsData = (data, sessionNumber) => {
        const allSessionsData =
            data && data.length > 0
                ? data.map(gd => {
                      return gd.allSessions.length > 0 ? gd.allSessions : [];
                  })
                : [];

        // get all score for this session from all groups
        var sessionScores = [];
        allSessionsData.forEach(gd => {
            for (var i = 0; i < gd.length; i++) {
                var sd = gd[i];
                if (sd.deployment.timepoint === sessionNumber) {
                    sessionScores.push(sd.devTags.ParentFidelitySessionScore);
                }
            }
        });

        // Fidelity scores are strings < 1 so we need to convert them to whole numbers
        const fidelityCompScores = sessionScores.map(score => Number(convertScore(score)));

        return fidelityCompScores.length > 0 ? calcAvg(fidelityCompScores).toFixed(0) : null;
    };

    if (props.noGroups) {
        return <NoGroupRole type="group" />;
    }

    if (props.noParticipants) {
        return <NoGroupRole type="participant" />;
    }

    if (noProgress) {
        return <NoGroupRole type="groupLeaderData" />;
    }

    if (loading) {
        return <Loader loading />;
    }

    const switchView = () => {
        setShowPrintMode(!showPrintMode);
    };

    return loading && loading ? (
        <Loader loading />
    ) : (
        <Report showPagination={showPrintMode}>
            <Page>
                <BrowserOnly>
                    <PrintView showPrintMode={showPrintMode} switchView={switchView} />
                </BrowserOnly>
                <div className={reportStyle.reportBox}>
                    <SummaryHeader {...headerProps} />
                    <div className={reportStyle.reportBody}>
                        {titleAndDescription}
                        <div>{renderTargetInfo()}</div>
                    </div>
                </div>
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
})(Component);
