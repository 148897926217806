import { handleActions, createAction } from 'redux-actions';
import { client } from 'cccisd-apollo';
import participantQuery from '../graphql/participantQuery.graphql';
import groupQuery from '../graphql/groupQuery.graphql';

const Fortress = window.cccisd && window.cccisd.fortress;

export const initialState = {
    loading: true,
    participantList: [],
    participant: null,
    groupList: [],
    group: null,
    sessionDataVariables: {},
    widgetsLoaded: [],
};

// Actions

const SET_LOADING = 'app/report/SET_LOADING';
const UNSET_LOADING = 'app/report/UNSET_LOADING';
const SET_PARTICIPANT = 'app/report/SET_PARTICIPANT';
const SET_GROUP = 'app/report/SET_GROUP';
const SET_PARTICIPANT_LIST = 'app/report/SET_PARTICIPANT_LIST';
const SET_GROUP_LIST = 'app/report/SET_GROUP_LIST';
const SET_SESSION_DATA_VARIABLES = 'app/report/SET_SESSION_DATA_VARIABLES';
const SET_NO_GROUPS = 'app/report/SET_NO_GROUPS';
const UNSET_NO_GROUPS = 'app/report/UNSET_NO_GROUPS';
const SET_NO_PARTICIPANTS = 'app/report/SET_NO_PARTICIPANTS';
const UNSET_NO_PARTICIPANTS = 'app/report/UNSET_NO_PARTICIPANTS';
const SET_WIDGETS_LOADED = 'app/report/SET_WIDGETS_LOADED';
const RESET_WIDGETS_LOADED = 'app/report/RESET_WIDGETS_LOADED';

// Action Creators
export const setLoading = createAction(SET_LOADING);
export const unsetLoading = createAction(UNSET_LOADING);
export const setParticipant = createAction(SET_PARTICIPANT);
export const setParticipantList = createAction(SET_PARTICIPANT_LIST);
export const setGroup = createAction(SET_GROUP);
export const setNoGroups = createAction(SET_NO_GROUPS);
export const unsetNoGroups = createAction(UNSET_NO_GROUPS);
export const setNoParticipants = createAction(SET_NO_PARTICIPANTS);
export const unsetNoParticipants = createAction(UNSET_NO_PARTICIPANTS);
export const setGroupList = createAction(SET_GROUP_LIST);
export const setSessionDataVariables = createAction(SET_SESSION_DATA_VARIABLES);
export const setWidgetsLoaded = createAction(SET_WIDGETS_LOADED);
export const resetWidgetsLoaded = createAction(RESET_WIDGETS_LOADED);

export const initialize = () => {
    return async (dispatch, getState) => {
        await dispatch(getGroups());
    };
};

const getParticipants = () => {
    return async (dispatch, getState) => {
        try {
            const nState = getState();

            // reset noParticipants before fetch
            if (nState.app.reportFilters.noParticipants) {
                dispatch(unsetNoParticipants());
            }

            const response = await client.query({
                query: participantQuery,
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
                variables: {
                    groupId: nState.app.reportFilters.group,
                },
            });

            const participantList = response.data.roles.participantList.map(item => {
                return { value: item.pawn.pawnId, label: item.fields.participantId };
            });

            dispatch(setParticipantList(participantList));
            if (participantList.length === 0) {
                await dispatch(setNoParticipants());
            } else {
                await dispatch(setParticipant(participantList[0].value));
            }
            await dispatch(unsetLoading());
        } catch (e) {
            console.error(e);
        }
    };
};

export const getGroups = () => {
    return async (dispatch, getState) => {
        try {
            const nState = getState();

            // reset noGroups before fetch
            if (nState.app.reportFilters.noGroups) {
                dispatch(unsetNoGroups());
            }

            const response = await client.query({
                query: groupQuery,
                variables: {
                    filter: Fortress.isSuperUser()
                        ? {}
                        : {
                              eq: {
                                  field: 'parentRoles.provider.pawn.pawnId',
                                  int: Fortress.user.acting.id,
                              },
                          },
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            });

            const groupList = response.data.roles.clientList.map(item => {
                return { value: item.pawn.pawnId, label: item.fields.clientLabel };
            });

            await dispatch(setGroupList(groupList));

            if (groupList.length === 0) {
                dispatch(setNoGroups());
            } else {
                await dispatch(setGroup(groupList[0].value));
                await dispatch(getParticipants());
            }
        } catch (e) {
            console.error(e);
        }
    };
};

export const updateGroup = id => {
    return async (dispatch, getState) => {
        const list = getState().app.reportFilters.groupList;
        const group = list.find(item => item.value === id);
        await dispatch(setGroup(group.value));
        // await dispatch(setLoading());
        await dispatch(getParticipants());
    };
};

export const updateParticipant = id => {
    return async (dispatch, getState) => {
        const list = getState().app.reportFilters.participantList;
        const participant = list.find(item => item.value === id);
        dispatch(setParticipant(participant.value));
    };
};

export const getSessionDataVariables = sessionType => {
    return async dispatch => {
        sessionType === 'child'
            ? dispatch(
                  setSessionDataVariables({
                      totalSessions: 24,
                      fidelityBenchmark: 80,
                  })
              )
            : dispatch(
                  setSessionDataVariables({
                      totalSessions: 10,
                      fidelityBenchmark: 80,
                  })
              );
    };
};

// Reducers
export default handleActions(
    {
        [SET_LOADING]: (state, action) => ({
            ...state,
            loading: true,
        }),
        [UNSET_LOADING]: (state, action) => ({
            ...state,
            loading: false,
        }),
        [SET_PARTICIPANT_LIST]: (state, action) => ({
            ...state,
            participantList: action.payload,
        }),
        [SET_PARTICIPANT]: (state, action) => ({
            ...state,
            participant: action.payload,
        }),

        [SET_GROUP_LIST]: (state, action) => ({
            ...state,
            groupList: action.payload,
        }),

        [SET_GROUP]: (state, action) => ({
            ...state,
            group: action.payload,
        }),

        [SET_SESSION_DATA_VARIABLES]: (state, action) => ({
            ...state,
            sessionDataVariables: action.payload,
        }),
        [SET_NO_GROUPS]: (state, action) => ({
            ...state,
            noGroups: true,
        }),
        [UNSET_NO_GROUPS]: (state, action) => ({
            ...state,
            noGroups: false,
        }),
        [SET_NO_PARTICIPANTS]: (state, action) => ({
            ...state,
            noParticipants: true,
        }),
        [UNSET_NO_PARTICIPANTS]: (state, action) => ({
            ...state,
            noParticipants: false,
        }),

        [SET_WIDGETS_LOADED]: (state, action) => {
            if (!state.widgetsLoaded.includes(action.payload)) {
                return { ...state, widgetsLoaded: [action.payload, ...state.widgetsLoaded] };
            }
            return state;
        },
        [RESET_WIDGETS_LOADED]: (state, action) => ({
            ...state,
            widgetsLoaded: [],
        }),
    },
    initialState
);
