import React from 'react';
import Tooltip from 'cccisd-tooltip';
import { Link } from 'react-router-dom';

import IconCalendar from 'cccisd-icons/calendar';
import IconReport from 'cccisd-icons/stats-dots';
import IconUsers from 'cccisd-icons/users';

const SummaryButtons = settings => {
    return ({ row }) => {
        const id = row && row['pawn.pawnId'] ? row['pawn.pawnId'] : '';

        const { type } = settings;

        return (
            <div className="text-center" style={{ display: 'flex', justifyContent: 'space-around', gap: '5px' }}>
                <div>
                    <Tooltip title={`Enter ${type} Session Data`}>
                        <Link to={type === 'Group' ? `/sessionData/${id}` : `/parentSessionData/${id}`}>
                            <button type="button" className="btn btn-success btn-sm">
                                {type === 'Group' ? <IconCalendar /> : <IconUsers />}
                            </button>
                        </Link>
                    </Tooltip>
                </div>
                <div>
                    <Tooltip title="View Reports">
                        <Link to="/reports">
                            <button type="button" className="btn btn-primary btn-sm">
                                <IconReport />
                            </button>
                        </Link>
                    </Tooltip>
                </div>
            </div>
        );
    };
};
export default SummaryButtons;
