import { Field } from 'cccisd-formik';

import React from 'react';
import ParticipantSelect from './filters/ParticipantSelect';
import GroupSelect from './filters/GroupSelect';
// Define filters you want to use

export default [
    {
        name: 'group',
        label: 'Select Group',
        component: props => {
            return (
                <Field name="group">{({ field, form }) => <GroupSelect {...props} field={field} form={form} />}</Field>
            );
        },
    },
    {
        name: 'participant',
        label: 'Select Participant',
        component: props => {
            return (
                <Field name="participant">
                    {({ field, form }) => <ParticipantSelect {...props} field={field} form={form} />}
                </Field>
            );
        },
    },
];
