import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page, BrowserOnly } from 'cccisd-laravel-report';
import Loader from 'cccisd-loader';
import { client } from 'cccisd-apollo';
import _format from 'date-fns/format';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionDataVariables } from '../../../../../reducers/reportFilters';

import widgets from '../widgets/widgets.js';
import ReportHeader from '../../common/components/ReportHeader';
import filterFields from '../../common/groupFilterField.js';
import { convertScore, getActualFields, calcAvg } from '../../common/helpers.js';
import Chart from './Chart';
import NoGroupRole from '../../common/components/NoGroupRole';
import PrintView from '../../common/components/PrintView';

import reportStyle from '../../common/reportStyle.css';
import query from './metrics.graphql';

import IconSmile from 'cccisd-icons/smile';
import IconSad from 'cccisd-icons/wondering';

const Component = props => {
    const [headerProps, setHeaderProps] = useState(null);
    const [loading, setLoading] = useState(true);
    const [noProgress, setNoProgress] = useState(null);
    const [overallScore, setOverallScore] = useState(null);
    const [chartData, setChartData] = useState([]);
    const [showPrintMode, setShowPrintMode] = useState(false);

    const { titleAndDescription, detailCharts, comments } = props.widgets;

    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            dispatch(getSessionDataVariables('parent'));
        })();
    }, [dispatch]);

    const { sessionDataVariables } = useSelector(state => state.app.reportFilters);

    useEffect(() => {
        // run metrics query
        if (noProgress === true) {
            setNoProgress(false);
        }

        (async () => {
            try {
                await client
                    .query({
                        query,
                        fetchPolicy: 'network-only',
                        variables: {
                            pawnId: +props.group,
                        },
                    })
                    .then(response => {
                        const group = response.data.roles.client;

                        const sessionDataDates = group.sessionDataDates;
                        const startDate = sessionDataDates.earliestCompleted !== null;
                        const endDate = sessionDataDates.latestCompleted !== null;

                        const firstDate = startDate
                            ? JSON.parse(group.sessionDataDates.earliestCompleted).sessionDate
                            : null;

                        const lastDate = endDate
                            ? JSON.parse(group.sessionDataDates.latestCompleted).sessionDate
                            : null;

                        const dateObj = {
                            startDate: startDate ? _format(new Date(firstDate), 'MM/dd/yyyy') : '',
                            endDate: endDate ? _format(new Date(lastDate), 'MM/dd/yyyy') : '',
                            now: _format(Date.now(), 'MM/dd/yyyy'),
                        };

                        if (!startDate || !dateObj) {
                            setNoProgress(true);
                        }

                        setOverallScore(convertScore(group.summary.fidelityAvg));

                        const fidelityData = [
                            {
                                x: 1,
                                y: getSessionAvg(group.sess1, group.sess1.devTags.ParentFidelitySessionScore),
                            },
                            {
                                x: 2,
                                y: getSessionAvg(group.sess2, group.sess2.devTags.ParentFidelitySessionScore),
                            },
                            {
                                x: 3,
                                y: getSessionAvg(group.sess3, group.sess3.devTags.ParentFidelitySessionScore),
                            },
                            {
                                x: 4,
                                y: getSessionAvg(group.sess4, group.sess4.devTags.ParentFidelitySessionScore),
                            },
                            {
                                x: 5,
                                y: getSessionAvg(group.sess5, group.sess5.devTags.ParentFidelitySessionScore),
                            },
                            {
                                x: 6,
                                y: getSessionAvg(group.sess6, group.sess6.devTags.ParentFidelitySessionScore),
                            },
                            {
                                x: 7,
                                y: getSessionAvg(group.sess7, group.sess7.devTags.ParentFidelitySessionScore),
                            },
                            {
                                x: 8,
                                y: getSessionAvg(group.sess8, group.sess8.devTags.ParentFidelitySessionScore),
                            },
                            {
                                x: 9,
                                y: getSessionAvg(group.sess9, group.sess9.devTags.ParentFidelitySessionScore),
                            },
                            {
                                x: 10,
                                y: getSessionAvg(group.sess10, group.sess10.devTags.ParentFidelitySessionScore),
                            },
                        ];

                        setChartData([
                            {
                                id: 'bigChart',
                                color: 'hsl(195, 100%, 30%)',
                                data: fidelityData,
                            },
                        ]);

                        setHeaderProps({
                            groupLabel: group.fields.clientLabel,
                            providerList: group.parentRoles.providerList,
                            dates: dateObj,
                            groupReport: true,
                        });
                        setLoading(false);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [props.filters]);

    const renderTargetInfo = () => {
        let icon = <IconSmile />;
        let color = '#3673b6';
        let targetText;

        if (+overallScore > sessionDataVariables.fidelityBenchmark) {
            targetText = 'Above Target';
        } else if (+overallScore === sessionDataVariables.fidelityBenchmark) {
            targetText = 'At Target';
        } else {
            icon = <IconSad />;
            color = '#d03916';
            targetText = 'Below Target';
        }

        const score = `${overallScore}%`;

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ textAlign: 'center', width: '30%' }}>
                    <div className={reportStyle.bigScore} style={{ color }}>
                        <div className={reportStyle.targetIndicator} style={{ color }}>
                            <h1>{targetText}</h1>
                        </div>
                        <div>{icon}</div>
                        <div>{score}</div>
                    </div>
                </div>
                <div style={{ width: '70%' }}>
                    <div style={{ height: '250px', width: '100%' }}>
                        <Chart data={chartData} sessionType="parent" />
                    </div>
                </div>
            </div>
        );
    };

    const getSessionAvg = (data, fidComp) => {
        const devTags = data.devTags;
        delete devTags.__typename;

        const allNull = Object.values(devTags).every(i => i === null);

        if (allNull) {
            return null;
        }

        const convertedFidScore = convertScore(fidComp);

        const scores = [+convertedFidScore];

        return calcAvg(scores).toFixed(0);
    };

    if (props.noGroups) {
        return <NoGroupRole type="group" />;
    }

    if (props.noParticipants) {
        return <NoGroupRole type="participant" />;
    }

    if (noProgress) {
        return <NoGroupRole type="groupData" />;
    }

    if (loading) {
        return <Loader loading />;
    }

    const switchView = () => {
        setShowPrintMode(!showPrintMode);
    };

    return loading && loading ? (
        <Loader loading />
    ) : (
        <Report showPagination={showPrintMode}>
            <Page>
                <BrowserOnly>
                    <PrintView showPrintMode={showPrintMode} switchView={switchView} />
                </BrowserOnly>
                <div className={reportStyle.reportBox}>
                    <ReportHeader {...headerProps} />
                    <div className={reportStyle.reportBody}>
                        {titleAndDescription}
                        <div>{renderTargetInfo()}</div>
                        <div style={{ textAlign: 'center', margin: '1em 1em' }}>
                            Greater treatment fidelity (80%-100%) is associated with better outcomes for participants.
                        </div>
                    </div>
                </div>
            </Page>
            <Page>{detailCharts}</Page>
            <Page>
                <hr style={{ marginTop: '1em' }} />
                {comments}
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
    getFilterFields: props => {
        return getActualFields(filterFields, props);
    },
})(Component);
