import React from 'react';
import { Field, CccisdToggle } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    progressTopic1: {
        show: true,
    },
    progressTopic2: {
        show: true,
    },
    progressTopic3: {
        show: true,
    },
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Progress Charts?" /> <hr />
            <Field name="progressTopic1.show" component={CccisdToggle} label="Show Progress Chart 1?" />
            <Field name="progressTopic2.show" component={CccisdToggle} label="Show Progress Chart 2?" />
            <Field name="progressTopic3.show" component={CccisdToggle} label="Show Progress Chart 3?" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
