import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import PrintIcon from 'cccisd-icons/page-break2';
import ReportIcon from 'cccisd-icons/pagebreak';

const PrintView = ({ showPrintMode, switchView }) => {
    return (
        <div style={{ textAlign: 'right', marginBottom: '20px' }}>
            <button type="button" className={`${style.printButton} btn btn-info`} onClick={switchView}>
                {showPrintMode ? (
                    <>
                        <ReportIcon /> Report View
                    </>
                ) : (
                    <>
                        <PrintIcon /> Print View
                    </>
                )}
            </button>
        </div>
    );
};

PrintView.propTypes = {
    showPrintMode: PropTypes.bool,
    switchView: PropTypes.func,
};
export default PrintView;
