import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { useSelector } from 'react-redux';

import chartsQuery from '../../charts.graphql';
import DetailChart from '../../../../common/components/DetailChart';
import _flatten from 'lodash/flatten';
import { lineChartColors } from '../../../../common/helpers.js';

const Component = props => {
    const [metrics, setMetrics] = useState([]);
    const [loading, setLoading] = useState(true);

    const { show } = props.settings;
    const { filters } = props;

    const { sessionDataVariables } = useSelector(state => state.app.reportFilters);

    useEffect(() => {
        (async () => {
            try {
                await client
                    .query({
                        query: chartsQuery,
                        fetchPolicy: 'network-only',
                        variables: {
                            pawnId: +filters.participant,
                        },
                    })
                    .then(response => {
                        const participantData = response.data.roles.participant.assignmentProgressList;

                        const valuesBySession = () => {
                            if (participantData.length > 0) {
                                return participantData.map(ap => {
                                    return {
                                        timepoint: +ap.deployment.timepoint,
                                        progressLabels: ap.progress.ChildGroupProgressGoal,
                                        progressScores: ap.progress.ChildGroupProgressPoints,
                                    };
                                });
                            }
                            return null;
                        };
                        const results = valuesBySession();

                        const allProgress = results.map(result => {
                            return result.progressLabels;
                        });

                        const progress = _flatten(allProgress).filter(prog => prog !== null);

                        const uniqueProgress = [...new Set(progress)];

                        const lookUpProgress = prog => {
                            const hasProgress = results.map(result => {
                                if (result.progressLabels && result.progressLabels.length > 0) {
                                    const index = result.progressLabels.indexOf(prog);

                                    if (result.progressLabels.includes(prog)) {
                                        return {
                                            id: prog,
                                            data: [
                                                {
                                                    x: result.timepoint,
                                                    y: result.progressScores[index],
                                                },
                                            ],
                                        };
                                    }
                                    return null;
                                }
                                return null;
                            });

                            return hasProgress.filter(res => res !== null);
                        };
                        const progressAndValues = uniqueProgress.map(prog => lookUpProgress(prog));

                        const chartData = (iss, len) => {
                            return [...Array(len).keys()].map(i => {
                                const label = iss[i][0].id;

                                // make an array with just the timepoint and value per progress
                                const scores = iss[i].map(thing => Array.from(thing.data));

                                return {
                                    id: `progress ${[i + 1]}`,
                                    settings:
                                        i <= 2
                                            ? {
                                                  ...props.settings[`progressTopic${[i + 1]}`],
                                              }
                                            : {},
                                    convertedData: [
                                        {
                                            id: label !== '' ? label : 'No issue label saved',
                                            data: _flatten(scores),
                                            color: lineChartColors[i],
                                        },
                                    ],
                                };
                            });
                        };

                        setMetrics(chartData(progressAndValues, progressAndValues.length));

                        setLoading(false);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [props.filters, props.settings]);

    return loading ? (
        <Loader type="inline" />
    ) : (
        <>
            {show && (
                <>
                    {metrics.map(metric => {
                        return (
                            <div key={metric.id}>
                                <DetailChart
                                    settings={metric.settings}
                                    data={metric.convertedData}
                                    sessionDataVariables={sessionDataVariables}
                                />
                            </div>
                        );
                    })}
                </>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    productIds: PropTypes.array,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
