import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import widgets from '../../common/widgets/widgets.js';
import ReportHeader from '../../common/components/ReportHeader';
import NoGroupRole from '../../common/components/NoGroupRole';
import PrintView from '../../common/components/PrintView';
import filterFields from '../../common/filterFields.js';
import style from './style.css';
import query from './metrics.graphql';
import { getDates, getActualFields } from '../../common/helpers.js';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page, BrowserOnly } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { ResponsiveBar } from 'cccisd-nivo/bar';

const Component = props => {
    const [metrics, setMetrics] = useState(null);
    const [headerProps, setHeaderProps] = useState(null);
    const [loading, setLoading] = useState(true);
    const [noProgress, setNoProgress] = useState(null);
    const [showPrintMode, setShowPrintMode] = useState(false);
    const [currentChart, setCurrentChart] = useState('overallScore');

    useEffect(() => {
        if (noProgress === true) {
            setNoProgress(false);
        }

        (async () => {
            try {
                await client
                    .query({
                        query,
                        fetchPolicy: 'network-only',
                        variables: {
                            pawnId: +props.filters.participant,
                        },
                    })
                    .then(response => {
                        const pre = response.data.roles.participant.preProgress.devTags;
                        const mid = response.data.roles.participant.midProgress.devTags;
                        const post = response.data.roles.participant.postProgress.devTags;
                        const followUp = response.data.roles.participant.followUpProgress.devTags;

                        const overallScore = {
                            pre: pre.SSBIEmotionalScore,
                            mid: mid.SSBIEmotionalScore,
                            post: post.SSBIEmotionalScore,
                            followUp: followUp.SSBIEmotionalScore,
                            preParent: pre.SSBIEmotionalScoreParent,
                            midParent: mid.SSBIEmotionalScoreParent,
                            postParent: post.SSBIEmotionalScoreParent,
                            followUpParent: followUp.SSBIEmotionalScoreParent,
                        };
                        const impulse = {
                            pre: pre.SSBIEmotionalImpulseControlScore,
                            mid: mid.SSBIEmotionalImpulseControlScore,
                            post: post.SSBIEmotionalImpulseControlScore,
                            followUp: followUp.SSBIEmotionalImpulseControlScore,
                            preParent: pre.SSBIEmotionalImpulseControlScoreParent,
                            midParent: mid.SSBIEmotionalImpulseControlScoreParent,
                            postParent: post.SSBIEmotionalImpulseControlScoreParent,
                            followUpParent: followUp.SSBIEmotionalImpulseControlScoreParent,
                        };
                        const emotional = {
                            pre: pre.SSBIEmotionalEmotionRegulationScore,
                            mid: mid.SSBIEmotionalEmotionRegulationScore,
                            post: post.SSBIEmotionalEmotionRegulationScore,
                            followUp: followUp.SSBIEmotionalEmotionRegulationScore,
                            preParent: pre.SSBIEmotionalEmotionRegulationScoreParent,
                            midParent: mid.SSBIEmotionalEmotionRegulationScoreParent,
                            postParent: post.SSBIEmotionalEmotionRegulationScoreParent,
                            followUpParent: followUp.SSBIEmotionalEmotionRegulationScoreParent,
                        };

                        const group = response.data.roles.participant.parentRoles.client;
                        const participant = response.data.roles.participant;

                        const allNull =
                            Object.values(pre)
                                .filter(item => item !== 'MetricsDevTags')
                                .every(item => !item) &&
                            Object.values(mid)
                                .filter(item => item !== 'MetricsDevTags')
                                .every(item => !item) &&
                            Object.values(post)
                                .filter(item => item !== 'MetricsDevTags')
                                .every(item => !item) &&
                            Object.values(followUp)
                                .filter(item => item !== 'MetricsDevTags')
                                .every(item => !item);

                        const dateObj = getDates(group);

                        if (!dateObj || allNull) {
                            setNoProgress(true);
                        }

                        setMetrics({
                            overallScore,
                            impulse,
                            emotional,
                        });

                        setHeaderProps({
                            participant: participant.fields.participantId,
                            groupLabel: group.fields.clientLabel,
                            providerList: group.parentRoles.providerList,
                            dates: dateObj,
                        });

                        setLoading(false);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [props.filters]);

    const switchView = () => {
        setShowPrintMode(!showPrintMode);
    };
    const handleClick = chartType => {
        setCurrentChart(chartType);
    };

    const chartText = {
        overallScore: {
            label: 'Overall Score',
            text: '',
        },
        impulse: {
            label: 'Impulse Control',
            text: 'Higher scores indicate greater ability to control impulses.',
        },
        emotional: {
            label: 'Emotional Regulation',
            text: 'Higher scores indicate greater ability to self-regulate emotions.',
        },
        overallScoreParent: {
            label: 'Overall Score Parent',
            text: '',
        },
        impulseParent: {
            label: 'Impulse Control Parent',
            text: 'Higher scores indicate greater ability to control impulses.',
        },
        emotionalParent: {
            label: 'Emotional Regulation Parent',
            text: 'Higher scores indicate greater ability to self-regulate emotions.',
        },
    };

    const renderBarChart = chart => {
        const colors = {
            'Overall Score Teacher': '#3266CC',
            'Impulse Control Teacher': '#3266CC',
            'Emotional Regulation Teacher': '#3266CC',
            'Overall Score Parent': '#FF7F0E',
            'Impulse Control Parent': '#FF7F0E',
            'Emotional Regulation Parent': '#FF7F0E',
        };

        const getColor = bar => colors[bar.id];

        const chartData = ['pre', 'mid', 'post', 'followUp'].map((item, i) => {
            if (metrics[chart][item] || metrics[chart][item] === 0) {
                if (i === 0) {
                    return {
                        timepoint: 'Pre',
                        [`${chartText[chart].label} Teacher`]: +metrics[chart][item],
                        [`${chartText[chart].label} Parent`]: +metrics[chart].preParent,
                    };
                }
                if (i === 1) {
                    return {
                        timepoint: 'Mid',
                        [`${chartText[chart].label} Teacher`]: +metrics[chart][item],
                        [`${chartText[chart].label} Parent`]: +metrics[chart].midParent,
                    };
                }
                if (i === 2) {
                    return {
                        timepoint: 'Post',
                        [`${chartText[chart].label} Teacher`]: +metrics[chart][item],
                        [`${chartText[chart].label} Parent`]: +metrics[chart].postParent,
                    };
                }
                if (i === 3) {
                    return {
                        timepoint: 'Follow Up',
                        [`${chartText[chart].label} Teacher`]: +metrics[chart][item],
                        [`${chartText[chart].label} Parent`]: +metrics[chart].followUpParent,
                    };
                }
            }

            if (metrics[chart][item] === null) {
                if (i === 0) {
                    return {
                        timepoint: 'Pre',
                        [`${chartText[chart].label} Teacher`]: 0,
                        [`${chartText[chart].label} Parent`]: 0,
                    };
                }
                if (i === 1) {
                    return {
                        timepoint: 'Mid',
                        [`${chartText[chart].label} Teacher`]: 0,
                        [`${chartText[chart].label} Parent`]: 0,
                    };
                }
                if (i === 2) {
                    return {
                        timepoint: 'Post',
                        [`${chartText[chart].label} Teacher`]: 0,
                        [`${chartText[chart].label} Parent`]: 0,
                    };
                }
                if (i === 3) {
                    return {
                        timepoint: 'Follow Up',
                        [`${chartText[chart].label} Teacher`]: 0,
                        [`${chartText[chart].label} Parent`]: 0,
                    };
                }
            }
            return null;
        });
        const getSettings = () => {
            const keys = {
                overallScore: ['Overall Score Teacher', 'Overall Score Parent'],
                impulse: ['Impulse Control Teacher', 'Impulse Control Parent'],
                emotional: ['Emotional Regulation Teacher', 'Emotional Regulation Parent'],
            };

            return {
                tickValues: [0, 1, 2, 3, 4, 5],
                keys: keys[chart],
                maxValue: 5,
            };
        };

        const chartSettings = getSettings();

        return (
            <div
                style={{
                    width: '100%',
                    height: '300px',
                    paddingLeft: '4em',
                    marginTop: '1em',
                }}
            >
                <ResponsiveBar
                    data={chartData}
                    keys={chartSettings.keys}
                    indexBy="timepoint"
                    groupMode="grouped"
                    colors={getColor}
                    maxValue={chartSettings.maxValue}
                    axisLeft={{
                        legend: 'Score',
                        legendPosition: 'middle',
                        legendOffset: -40,
                        tickValues: chartSettings.tickValues,
                    }}
                    labelFormat={d => <tspan y={-10}>{Math.round(+d * 100) / 100}</tspan>}
                    labelTextColor="#ffffff"
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                />
            </div>
        );
    };

    const renderChartButtons = () => {
        const buttons = [
            { label: 'Overall Score', chartType: 'overallScore' },
            { label: 'Impulse Control', chartType: 'impulse' },
            { label: 'Emotional Regulation', chartType: 'emotional' },
        ];
        const buttonElements = (
            <>
                {buttons.map((item, i) => {
                    const isCurrentChart = item.chartType === currentChart;
                    const topRow = i < 3;
                    return (
                        <button
                            type="button"
                            className={isCurrentChart ? style.chartButtonActive : style.chartButton}
                            style={topRow ? { marginBottom: '20px' } : {}}
                            onClick={() => {
                                handleClick(item.chartType);
                            }}
                        >
                            {item.label}
                        </button>
                    );
                })}
            </>
        );
        return buttonElements;
    };

    const renderPrintCharts = chart => {
        return (
            <div className={style.additionalReportBox}>
                <div
                    style={{
                        fontSize: '14pt',
                        marginBottom: '0.5em',
                    }}
                >
                    {chartText[chart].label} Score
                </div>
                <p>{chartText[chart].text} </p>
                {renderBarChart(chart)}
            </div>
        );
    };
    if (props.noGroups) {
        return <NoGroupRole type="group" />;
    }

    if (props.noParticipants) {
        return <NoGroupRole type="participant" />;
    }

    if (noProgress) {
        return <NoGroupRole type="data" />;
    }

    if (loading) {
        return <Loader loading />;
    }

    return (
        <div className={style.reportContainer}>
            <Report showPagination={showPrintMode}>
                <Page>
                    <BrowserOnly>
                        <PrintView showPrintMode={showPrintMode} switchView={switchView} />
                    </BrowserOnly>
                    <div className={style.reportBox}>
                        <ReportHeader {...headerProps} />
                        <div className={style.reportBody}>
                            <div className={style.titleRow}>
                                <h1>SSBI: Impulse Control and Emotion Regulation Report</h1>
                            </div>
                            <p>
                                {`For these social emotional skills scales, higher mean scores indicate greater social skills and social functioning in that area.`}
                            </p>
                            <div
                                style={{
                                    fontSize: '14pt',
                                    marginBottom: '0.5em',
                                    marginTop: '1em',
                                }}
                            >
                                {chartText[currentChart].label}
                            </div>
                            <p>{chartText[currentChart].text} </p>
                            {renderBarChart(currentChart)}
                            <BrowserOnly>
                                <div
                                    className={style.chartButtons}
                                    style={showPrintMode ? { visibility: 'hidden' } : {}}
                                >
                                    {renderChartButtons()}
                                </div>
                            </BrowserOnly>
                        </div>
                    </div>
                </Page>
                <div style={{ display: showPrintMode ? 'block' : 'none' }}>
                    <Page>
                        {['overallScore']
                            .filter(item => item !== currentChart)
                            .map(chart => {
                                return <>{renderPrintCharts(chart)}</>;
                            })}
                    </Page>
                    <Page>
                        {['impulse', 'emotional']
                            .filter(item => item !== currentChart)
                            .map(chart => {
                                return <>{renderPrintCharts(chart)}</>;
                            })}
                    </Page>
                </div>
            </Report>
        </div>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    // redux
    loading: PropTypes.bool,
    participant: PropTypes.number,
    group: PropTypes.number,
    noGroups: PropTypes.bool,
    noParticipants: PropTypes.bool,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
    getFilterFields: props => {
        return getActualFields(filterFields, props);
    },
})(Component);
