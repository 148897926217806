import React from 'react';
import Header from 'cccisd-header';

import style from './style.css';

const AppHeader = () => {
    return (
        <>
            <Header
                containerClassName="container-fluid"
                wrapperClassName={style.wrapper}
                logoClassName={style.logo}
                primaryNavLinks={[]}
                secondaryNavLinks="mainNav"
                showAlertAction={false}
            />
            <strong className={style.name}>Coping Power Program</strong>
        </>
    );
};

export default AppHeader;
