import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'childFidelitySummary',
    label: 'Child Fidelity Summary',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
