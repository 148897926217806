import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import PropTypes from 'prop-types';
import { initialize } from 'js/reducers/reportFilters.js';
import Component from './component.js';

import Loader from 'cccisd-loader';
const Wrapper = props => {
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            await dispatch(initialize());
        })();
    }, []);

    return (
        <>
            <Loader loading={props.loading} removeChildren>
                <Component {...props} />
            </Loader>
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    // redux
    loading: PropTypes.bool,
    participant: PropTypes.number,
    group: PropTypes.number,
    noGroups: PropTypes.bool,
    noParticipants: PropTypes.bool,
};

const mapStateToProps = state => ({
    loading: state.app.reportFilters.loading,
    participant: state.app.reportFilters.participant,
    group: state.app.reportFilters.group,
    noGroups: state.app.reportFilters.noGroups,
    noParticipants: state.app.reportFilters.noParticipants,
});

export default connect(mapStateToProps, {
    initialize,
})(Wrapper);
