import React from 'react';
import { Field, CccisdWysiwyg } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    title: 'Summary Fidelity Report',
    description:
        'The Summary Fidelity Report shows how well you are adhering to the treatment model across all of your groups.',
};

const Component = props => {
    return (
        <div>
            <Field name="title" component={CccisdWysiwyg} label="Title" />
            <Field name="description" component={CccisdWysiwyg} label="Description" />
        </div>
    );
};

// widgetBuilder is a Formik Wrapper.
export default widgetBuilder({ initialValues })(Component);
