import React from 'react';
import PropTypes from 'prop-types';
import { CccisdSelect } from 'cccisd-formik';
import { connect, useDispatch } from 'react-redux';
import { updateParticipant } from 'js/reducers/reportFilters.js';

const ParticipantSelect = props => {
    const dispatch = useDispatch();

    return (
        <CccisdSelect
            field={props.field}
            form={props.form}
            options={props.participantList}
            label="Participants"
            onChange={e => {
                props.form.handleChange(e);
                dispatch(updateParticipant(+e.target.value));
            }}
        />
    );
};

ParticipantSelect.propTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
    // from redux
    participantList: PropTypes.array,
};
const mapStateToProps = state => ({
    participantList: state.app.reportFilters.participantList,
});

export default connect(mapStateToProps, { updateParticipant })(ParticipantSelect);
