import React from 'react';

const NoOptions = ({ type }) => {
    const key = {
        participant: 'No participants were found for this group.',
        group: 'No groups were found.',
        data: 'No data was found for this participant.',
        groupData: 'No data was found for this group.',
        groupLeaderData: 'No data was found for this group leader.',
    };

    return (
        <div className="alert alert-warning" style={{ textAlign: 'center' }}>
            <h3>{key[type]}</h3>
        </div>
    );
};

export default NoOptions;
