import React from 'react';
import PropTypes from 'prop-types';

import style from './style.css';

const ReportHeader = props => {
    const { idNumber, groupCount, participantCount, dates } = props;

    return (
        <div className={style.header}>
            <div className={style.topRow}>
                <h1>Fidelity Summary</h1>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <div>
                            <strong>Clinician ID:</strong> {idNumber}
                        </div>
                        <div>
                            <strong>Number of Groups:</strong> {groupCount}
                        </div>
                        <div>
                            <strong>Participating Individuals:</strong> {participantCount}
                        </div>
                    </div>
                    <div>
                        <div>
                            <strong>Dates of Service: </strong>
                            <div>
                                {dates.startDate} <strong>to</strong> {dates.endDate}
                            </div>
                        </div>
                        <div>
                            <strong>Date Report Created: </strong>
                            {dates.now}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
ReportHeader.propTypes = {
    idNumber: PropTypes.string,
    groupCount: PropTypes.number,
    participantCount: PropTypes.number,
    dates: PropTypes.object,
    groupReport: PropTypes.bool,
};
export default ReportHeader;
